<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">
    <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
      <!-- Sign In Form Column -->
      <a-col
        :span="24"
        :md="12"
        :lg="{ span: 12, offset: 0 }"
        :xl="{ span: 6, offset: 2 }"
        class="col-form"
      >
        <h1 class="mb-15">登录</h1>
        <h5 class="font-regular text-muted">输入邮箱密码以登录</h5>

        <!-- Sign In Form -->
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <a-form-item class="mb-10" label="邮箱" :colon="false">
            <a-input
              v-decorator="[
                'email',
                { rules: [{ required: true, message: '请输入您的邮箱!' }] },
              ]"
              placeholder="邮箱"
            />
          </a-form-item>
          <a-form-item class="mb-5" label="密码" :colon="false">
            <a-input
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入您的密码!' }] },
              ]"
              type="password"
              placeholder="密码"
            />
          </a-form-item>
          <a-form-item class="mb-10">
            <a-switch v-model="rememberMe" /> 记住密码
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->
        <!-- <p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p> -->
      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
        <img src="images/img-signin.jpg" alt="" />
      </a-col>
      <!-- Sign In Image Column -->
    </a-row>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { setToken,setUser } from "../utils/index";
import router from "../router/index";
export default {
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const { email, password } = values;
          login(email, password).then((res) => {
            if(res.code == 0){
				this.$message.success("登录成功")
				setToken(res.data.token)
        setUser(res.data.type)
				router.push('/dashboard'); 
			}else{
				this.$message.error("登录失败")
			}
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>